@import '../../../../scss/theme-bootstrap';

.tabbed-products-block {
  margin: 0 auto;
  max-width: 1281px;
  &__tabs {
    margin: 10px auto 0 auto;
    text-align: center;
    border-bottom: 1px solid $color--gray--light;
  }
  &__tab {
    background-color: $color--white;
    border: 1px solid $color--gray--light;
    color: $color--black;
    cursor: pointer;
    display: block;
    float: #{$ldirection};
    padding: 16px 8px;
    text-transform: uppercase;
    width: 50%;
    border-bottom: none;
    @if $fonts_update == true {
      font-size: 11px;
      @include breakpoint($bp--medium-up) {
        font-size: 13px;
      }
      @include breakpoint($bp--large-up) {
        font-size: 15px;
      }
    }
    @include breakpoint($bp--large-up) {
      padding: 16px;
    }
    &:visited {
      border-bottom: 1px solid $color--gray--light;
      color: $color--black;
    }
    &:not(.current):hover {
      background-color: $color--black;
      border: 1px solid $color--gray--light;
      color: $color--white;
    }
    &.current {
      background-color: $color--black;
      border: transparent solid 1px;
      color: $color--white;
    }
  }
  &__content-item {
    height: 0;
    width: 100%;
    overflow: hidden;
    transition: opacity 1s ease-in-out;
    opacity: 0;
    &.current {
      height: auto;
      overflow: visible;
      opacity: 1;
    }
  }
  .product-brief {
    &-carousel {
      border-top: none;
    }
    &__shade {
      line-height: 1.3;
      float: #{$ldirection};
    }
    &__badge,
    &__product-name {
      @if $fonts_update == true {
        font-family: $helvetica-font;
        font-size: 11px;
        letter-spacing: 0;
      }
    }
    &__product-name {
      padding-#{$rdirection}: 5px;
    }
    &__price {
      .product-price {
        @if $fonts_update == true {
          font-family: $helvetica-font;
          font-size: 11px;
          letter-spacing: 0;
        }
      }
    }
    &-cta,
    .product-add-to-bag {
      @if $fonts_update == true {
        @include h6();
        height: 45px;
        line-height: 45px;
        padding: 0 30px;
      }
    }
    &__image-link {
      padding-bottom: 100%;
      position: relative;
    }
    &__image-img {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0; // used for center position, no RTL require.
      right: 0; // used for center position, no RTL require.
      &--alt {
        transform: unset;
      }
    }
  }
  .homepage-formatter & {
    .block-eyebrow--header,
    .block-headline--header {
      margin-#{$ldirection}: 36px;
    }
  }
  .grid--mpp__carousel {
    animation-duration: 1.5s;
  }
  .grid--mpp__item {
    display: none;
    &:first-child {
      display: block;
    }
  }
  .grid--mpp__items {
    &.slick-initialized {
      .grid--mpp__item {
        display: block;
      }
    }
  }
}
